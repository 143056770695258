<template>
  <div class="page--report">
    <div class="filter-wrap">
      <!-- <Component
        v-for="obj in genFilterObj()"
        :option="options[obj.key]"
        :setup="{ key: obj.key, ...obj.props }"
        :key="obj.key"
        :is="filterMap[obj.type]"
        :range="needRange(obj.props)"
        v-model="rawFilter[obj.key]"
        @input="onFilterAction"
      /> -->
      
      <div class="row" v-if="isIncludeDate()">
        <div class="date-wrap">
          <div class="label" v-t="`label.from`" v-if="setting.range"/>
          <div class="selection-wrap">
            <FLSelection
              class="year"
              :title="$t('label.year')"
              :option="year_options"
              :closeable="true"
              @input="updateDaterange('from','year',$event)"
              :value="daterange.from.year"
              v-if="isIncludeFilter('year')"
            />
            <FLSelection
              class="month"
              :title="$t('label.month')"
              :option="months_options"
              :closeable="true"
              @input="updateDaterange('from','month',$event)"
              :value="daterange.from.month"
              v-if="isIncludeFilter('month')"
            />
            <FLSelection
              class="day"
              :title="$t('label.day')"
              :option="from_date_options"
              :closeable="true"
              @input="updateDaterange('from','day',$event)"
              :value="daterange.from.day"
              v-if="isIncludeFilter('day')"
            />
          </div>
        </div>
        <div class="space"></div>
        <div class="date-wrap" v-if="setting.range">
          <div class="label" v-t="`label.to`"/>
          <div class="selection-wrap">
            <FLSelection
              class="year"
              :title="$t('label.year')"
              :option="to_year_options"
              :closeable="true"
              @input="updateDaterange('to','year',$event)"
              :value="daterange.to.year"
              v-if="isIncludeFilter('year')"
            />
            <FLSelection
              class="month"
              :title="$t('label.month')"
              :option="to_months_options"
              :closeable="true"
              @input="updateDaterange('to','month',$event)"
              :value="daterange.to.month"
              v-if="isIncludeFilter('month')"
            />
            <FLSelection
              class="day"
              :title="$t('label.day')"
              :option="to_date_options"
              :closeable="true"
              @input="updateDaterange('to','day',$event)"
              :value="daterange.to.day"
              v-if="isIncludeFilter('day')"
            />
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="inner-wrap">
          <div class="label">{{ $t('menu.item.car_park') }}</div>
          <Selection
            :title="$t('menu.item.car_park')"
            :option="options.carPark"
            v-model="filter.carPark"
            :closeable="true"
            :filterable="true"
            :setup="{ label: 'externalKey', value: 'id' }"
            v-if="isIncludeFilter('car_park')"
          />
        </div>
      </div>
      <div class="row align-left">
        <EvButton @click="exportResult" class="export-btn">{{ $t("button.export") }}</EvButton>
      </div>
    </div>
    <!-- <div class="option-wrapper">
      <FLSelection
        :title="$t('label.year')"
        :option="year_options"
        :closeable="true"
      />
      <FLSelection
        :title="$t('label.month')"
        :option="months_options"
        :closeable="true"
      />
      <div class="space"></div>
      <EvButton>{{ $t("button.confirm") }}</EvButton>
    </div> -->
    <!-- <Datatable
      :rows="list"
      :fields="setting.list"
      :config="setting.fields"
      :action="setting.action"/> -->
  </div>
</template>

<script>
import FLSelection from "@/components/widgets/FLSelection";
import EvButton from "@/components/modules/EvButton";
import DateInputBox from "@/components/modules/form/DateInputBox";
import Datatable from "@/components/widgets/Datatable/Datatable";

export default {
  components: {
    FLSelection,
    EvButton,
    Datatable
  },
  async beforeMount() {
    let today = new Date();
    let yearObj = { label: today.getFullYear(), value: today.getFullYear() };
    let monthObj = { label: today.getMonth()+1, value: today.getMonth()+1 };
    let dayObj = { label: today.getDate(), value: today.getDate() };
    this.daterange.from.year = yearObj;
    this.daterange.to.year = yearObj;
    this.daterange.from.month = monthObj;
    this.daterange.to.month = monthObj;
    this.daterange.from.day = { label: 1, value: 1 };
    this.daterange.to.day = dayObj;

    Object.assign(this.setting, this.$setting.report[this.$route.params.key]);
    // action exist
    // if(this.setting.action && this.setting.action.length || this.setting?.filters) {
    //   if(!this.setting.list.includes("action-btn")) {
    //     this.setting.list.push("action-btn");
    //   }
    // }
    this.needLoading(true);
    // this.list = (await this.$axios.get(`/report/list/${this.$route.params.key}`)).data;
    this.options = (await this.$axios.get(`/options`, {
        params: {
          opts: ["carPark"]
        }
      })).data.response.options;
    this.needLoading(false);

  },
  methods: {
    async exportResult() {
      let filter = {};
      // await this.$axios.get(`/report/${this.$route.params.key}`);
      if(this.daterange.from.year && this.daterange.from.month && this.daterange.to.year && this.daterange.to.month) {
        let from = null;
        let to = null;
        if(this.setting.filters.includes("day")) {
          from = this.$moment(`${this.daterange.from.year}-${this.daterange.from.month}-${this.daterange.from.day}`, "YYYY-MM-DD");
          to = this.$moment(`${this.daterange.to.year}-${this.daterange.to.month}-${this.daterange.to.day}`, "YYYY-MM-DD");
          to = to.add(1, 'days').subtract(1, 'seconds');
        }else {
          from = this.$moment(`${this.daterange.from.year}-${this.daterange.from.month}`, "YYYY-MM");
          to = this.$moment(`${this.daterange.to.year}-${this.daterange.to.month}`, "YYYY-MM");
          to = to.add(1, 'months').subtract(1, 'seconds');
        }
  
        filter = {
          daterange: this.setting.range ? [from.format("X"),to.format("X")] : undefined,
          year: this.daterange.from.year,
          month: this.daterange.from.month,
          car_park_id: this.filter?.carPark?.id
        }
      }
      this.needLoading(true);
      await this.exportExcel(this.$route.params.key, filter, `/report/${this.$route.params.key}`);
      this.needLoading(false);
      return true;
    },
    needRange(props) {
      if(typeof props == "undefined") return true;
      console.log(props);
      if(props.hasOwnProperty("range")) return props.range;
      return true;
    },
    onFilterAction() {
      Object.entries(this.rawFilter).map(([k,v])=>{
        let conf = this.setting?.fields[k];
        let lang_prefix = "";
        if(conf?.props?.locale) {
          let langMap = {
            en: "en",
            zh: "ch"
          };
          lang_prefix = `_${langMap[this.$i18n.locale]}`;
        }
        if(conf?.type == "object" && v) {
          console.log("value: ",v);
          this.filter[k] = v[conf?.props?.value];
        }else if(conf?.type == "datetime" && v != null) {
          console.log("datetime: ", v);
          if(conf?.props?.type == "time") {
            this.filter[k] = this.$moment(v).format("HH:mm");
          }else if(Array.isArray(v)) {
            this.filter[k] = v.map(d=>this.$moment(d).format("X"));
          }else {
            this.filter[k] = this.$moment(v).format("X");
          }
        }else {
          this.filter[`${k}${lang_prefix}`] = v;
        }
      });
    },
    onFilterPressEnter(type) {
      if(type != "text") return;
      this.onFilterAction('search');
    },
    updateDaterange(type, field , e) {
      this.daterange[type][field] = e.value;
    },
    isIncludeFilter(key) {
      return this.setting?.filters?.includes(key);
    },
    isIncludeDate() {
      return this.setting?.filters?.includes("year") || this.setting?.filters?.includes("month") || this.setting?.filters?.includes("day");
    }
  },
  computed: {
    year_options() {
      let y = this.$moment().year();
      let items = [];
      for(let i=0; i<7; i++) {
        items.push({ label: y-i, value: y-i });
      }
      return items;
    },
    to_year_options() {
      let from = this.daterange.from.year != null ? this.daterange.from.year : 0;
      let y = this.$moment().year();
      let items = [];
      for(let i=0; i<7; i++) {
        if(y-i < from) continue;
        items.push({ label: y-i, value: y-i });
      }
      return items;
    },
    months_options() {
      let items = [];
      for(let i=1; i<=12; i++) {
        items.push({ label: i, value: i });
      }
      return items;
    },
    to_months_options() {
      let fromY = this.daterange.from.year != null ? this.daterange.from.year : 0;
      let toY = this.daterange.to.year != null ? this.daterange.to.year : 0;
      let fromM = this.daterange.from.month != null ? this.daterange.from.month : 0;
      let items = [];
      for(let i=1; i<=12; i++) {
        if(fromY == toY && i < fromM) continue;
        items.push({ label: i, value: i });
      }
      return items;
    },
    from_date_options() {
      let maxDay = this.$moment().set("month",this.daterange.from.month-1).daysInMonth();
      let days = [];
      for(let i=0;i<maxDay;i++) {
        days.push({ label: i+1, value: i+1 });
      }
      return days;
    },
    to_date_options() {
      let fromY = this.daterange.from.year != null ? this.daterange.from.year : 0;
      let toY = this.daterange.to.year != null ? this.daterange.to.year : 0;
      let fromD = this.daterange.from.day != null ? this.daterange.from.day : 0;
      let maxDay = this.$moment().set("month",this.daterange.to.month-1).daysInMonth();
      let days = [];
      for(let i=0;i<maxDay;i++) {
        if(fromY == toY && i < fromD-1) continue;
        days.push({ label: i+1, value: i+1 });
      }
      return days;
    },

  },
  data() {
    return {
      setting: {},
      objs: [],
      options: [],
      filterMap: {
        object: FLSelection,
        datetime: DateInputBox,
      },
      filter: {},
      rawFilter: {},
      list: [],
      daterange: {
        from: {
          year: undefined,
          month: undefined,
          day: undefined
        },
        to: {
          year: undefined,
          month: undefined,
          day: undefined
        }
      },
      options: {
        car_park: []
      }
    }
  }
}
</script>

<style lang="scss">
.page--report{
  width: 100%;
  display: flex;
  justify-content: center;
  & > .filter-wrap{
    width: 700px;
    max-width: 95%;
    .label{
      display: flex;
      align-items: center;
      
    }
    .divider{
      height: 1.5px;
      background: black;
      margin: 25px 30px;
      opacity: .1;
    }
    .row{
      display: flex;
      gap: 10px;
      &:not(:first-child) {
        // margin-top: 20px;
      }
      &.align-left{
        justify-content: flex-end;
      }
      .date-wrap{
        .selection-wrap{
          margin-top: 15px;
          display: flex;
          gap: 10px;
          .year{
            .inner-wrap{
              width: 100px;
            }
          }
          .month{
            .inner-wrap{
              width: 80px
            }
          }
          .day{
            .inner-wrap{
              width: 80px;
            }
          }
        }
      }
    }
  }
  .widget--datatable{
    margin-top: 20px;
  }
  .export-btn{
    margin-top: 30px;
  }
  .widget--form_selection{
    margin-top: 10px;
    & > .inner-wrap{
      height: 35px;
      .option{
        height: 35px;
      }
    }
  }
}
</style>